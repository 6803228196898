<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const { playMysteryBoxAudio } = useMysteryBoxAudios();
const { isEventBox } = useEventsCollector();
const { isSupercharged, isRegular, isActiveSuperchargedMode } = useMysteryBoxes();

const mysteryImage = computed(() => {
	if (isEventBox.value) {
		return "/nuxt-img/dice-dash/widget.png";
	}

	if (isSupercharged.value) {
		return "/nuxt-img/mystery-box/main-banner-super-box.png";
	}

	return "/nuxt-img/mystery-box/main-banner-mystery-box.png";
});

const handleMagicBoxClick = () => {
	if (appInitData.value?.isGuest === false) {
		open("LazyOModalMysteryBox");
		playMysteryBoxAudio();
	}
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg
			v-if="isSupercharged || isRegular"
			:src="`/nuxt-img/mystery-box/label-${isRegular ? 'regular' : 'super'}.png`"
			:class="['super-box', { regular: isRegular }]"
			:width="isRegular ? '286' : '414'"
			height="114"
			:alt="`label ${isRegular ? 'regular' : 'super'} box`"
			format="avif"
			loading="lazy"
		/>
		<NuxtImg
			:class="['image', { super: isSupercharged }]"
			:src="mysteryImage"
			alt="Banner-mistery"
			width="183"
			height="209"
			format="avif"
			loading="lazy"
		/>
		<div class="content">
			<AText variant="toledo" :modifiers="['semibold']">
				<span>{{ isEventBox ? t("Dice Dash") : t("Mystery Box") }}</span>
			</AText>
			<AText variant="taipei" :modifiers="['bold']" class="text-tlalnepantla label">
				<span>{{ t("Open NOW!") }}</span>
			</AText>
			<AButton variant="primary" class="btn" size="lg" @click="handleMagicBoxClick">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ isEventBox || isActiveSuperchargedMode ? t("Open") : t("Open Box") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 295px;
	min-width: 280px;
	height: 228px;
	border-radius: 16px;
	background: var(--gosaba);
	display: flex;
	justify-content: flex-end;
	padding: gutter(3) gutter(2) gutter(4) gutter(2);
	position: relative;
	overflow: visible;

	&::before {
		content: "";
		background: linear-gradient(180deg, rgba(35, 25, 87, 0) 0%, #0f063d 100%);
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
	}

	@include media-breakpoint-down(lg) {
		min-width: 295px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.content {
	width: 110px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: gutter(0.5);
	position: relative;
	z-index: 1;
}

.image {
	position: absolute;
	left: 0;
	bottom: 0;

	&.super {
		top: 0;
		bottom: unset;
	}
}

.label {
	line-height: 1.1;
	text-shadow: 0px 0px 6px var(--carapicuiba);
}

.btn {
	width: 100%;
	margin-top: gutter(2);
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}

.super-box {
	position: absolute;
	right: 12px;
	top: -16px;
	width: 130px;
	height: 36px;
	transform: rotate(13deg);

	&.regular {
		width: 92px;
		right: 44px;
		top: -22px;
	}
}
</style>
